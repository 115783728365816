import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();

//get the live api url functions
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
//get the language id functions
const language_id = ApiHelperData.userheadersData

//call the research achivement arrows functions and passing parameters
const getChineseHerbal_Database = (herbalParams) => {//getChineseHerbal_Database
  
    var herbal_service_url;
    //check the conditions
    if (language_id.locale == "en") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}-tc&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}-sc&locale=` + language_id.locale
    }

    return axios.get(herbal_service_url)
        .then((response) => {
            console.log("useful_links_url", herbal_service_url)
            console.log("response", response.data)
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}


//Exporting to the Herbal Garden Services
const WhiskersChineseHerbalDataBaseService = {
    getChineseHerbal_Database
}
export default WhiskersChineseHerbalDataBaseService;