import axios from "axios";
import ApiHelper from "../helper/ApiHelper";

///call the api helper functions
const ApiHelperData = ApiHelper.getCMCRCData();
///get the live api url
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
///get the user headers api url
const language_id = ApiHelperData.userheadersData

//call the get about us api functions
const getAbout_Us = (aboutParams) => {
    var about_service_url;
    //check the conditons 
    if (language_id.locale == "en") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${aboutParams}&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${aboutParams}-tc&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${aboutParams}-sc&locale=` + language_id.locale
    }
   


    return axios.get(about_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//Exporting About us Services
const AboutServices = {
    getAbout_Us
}
export default AboutServices;