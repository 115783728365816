import React, { useEffect, useState } from 'react';
import './Members.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MemberOrganizationServices from '../../services/Member.service';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

import { Loader } from './Loader';

const Members = () => {
    const language_Slug = localStorage.getItem('lang')
    const member_Slug = useLocation();
    const member_Params = member_Slug.pathname.split('/')[1]
    const [allmemberData, setMembersData] = useState([])
    const [allorgatizationData, setorganiZationData] = useState([])
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        setLoading(true)
        MemberOrganizationServices.getOrganizationStructureApi().then((response) => {
            const allOrganizationData = response.data
            setLoading(false)
            setorganiZationData(allOrganizationData)
        })

        MemberOrganizationServices.getMember_OrganizationApi(member_Params).then((response) => {
            const allmemberData = response.data
            setMembersData(allmemberData)
        })

    }, [language_Slug, member_Params])

    //English Filter Data
    let mainParentData = []
    let ChineneseparentData = []
    //Main Parent data
    for (let i = 0; i < allorgatizationData.length; i++) {
        if (allorgatizationData[i]?.attributes?.Parent?.data) {

        }
        else {
            mainParentData.push(allorgatizationData[i])
        }
    }

    //Parent Data
    for (let k = 0; k < allorgatizationData.length; k++) {
        if (mainParentData[0]?.id == allorgatizationData[k]?.attributes?.Parent?.data?.id) {
            ChineneseparentData.push(allorgatizationData[k])
        }
    }

    console.log(ChineneseparentData)

    //filter all Organization Data and Member data
    //Add Member Data as key value pairs form
    // console.log("allorgatizationData");     
    // console.log(allorgatizationData);  
    for (let i = 0; i < allorgatizationData.length; i++) {
        let memberList = []
        for (let k = 0; k < allmemberData.length; k++) {
            if (allorgatizationData[i]?.id == allmemberData[k]?.attributes?.org_structure?.data?.id) {
                // console.log(allmemberData[k]?.attributes?.org_structure?.data?.id + allmemberData[k].attributes.Name)                
                memberList.push({
                    'Name': allmemberData[k].attributes.Name,
                    'Designation': allmemberData[k].attributes.Designation,
                    'URLRedirection': allmemberData[k].attributes.URLRedirection
                },
                )
            }
        }

        allorgatizationData[i].attributes['MemberData'] = memberList
    }
    //removing first parent elements of an array using slice method
    const NewOrganizationData = allorgatizationData.slice(ChineneseparentData.length + 1)

    let NewOrganizationData_2 = [];
    let NewOrganizationData_3 = [];
    let NewOrganizationData_4 = [];

    for(let a = 0; a < NewOrganizationData.length; a++ ){
        switch(NewOrganizationData[a].attributes.Parent.data.id) {
            case 2:
            case 6:
            case 26:
                NewOrganizationData_2.push(NewOrganizationData[a]);
                break;
            case 3:
            case 7:
            case 27:
                NewOrganizationData_3.push(NewOrganizationData[a]);     
                break;  
            case 8:
            case 4:
            case 28:
                NewOrganizationData_4.push(NewOrganizationData[a]);     
                break;                            
          }
    }

console.log(NewOrganizationData_2.length);
console.log(NewOrganizationData_3.length);
console.log(NewOrganizationData_4.length);

    return (

        <div>
            <section className='member-section-block'>
                <div className='loder-block'>
                    {loading && <Loader />}
                </div>
                <div className='member-section-inner'>
                    <h2>{t("members")}</h2>
                    <div className='members-content-block top-block'>
                        <div className='members-content'>
                            <div className='members-info-block'>
                                {mainParentData.length ? mainParentData.map((mainParentData, index) => (
                                    (mainParentData?.attributes?.URLRedirection) ?
                                    <Link to={mainParentData?.attributes?.URLRedirection} target="_blank" style={{ textDecoration: 'none', color: '#620a0c' }} key={index}>
                                        <span className='members-info-details'>
                                            <h4>{mainParentData?.attributes?.Title}</h4>
                                        </span>
                                    </Link> 
                                    :
                                    <span className='members-info-details'>
                                        <h4>{mainParentData?.attributes?.Title}</h4>
                                    </span>
                                )) : null}

                            </div>
                            {ChineneseparentData ?
                                <div className='direction-path-image top-line'>
                                        <i class="chart-color chart-arrow-up2 fa-solid fa-arrow-up"></i>
                                </div>
                                : null}

                        </div>
                    </div>


                    <Tabs>
                        <TabList>
                            {ChineneseparentData.length ? ChineneseparentData.map((childParentData, id) => (
                                <Tab key={id} index={id}>
                                    {id == 0 && <div className="chart-line2 start-line"></div>}
                                          {(id < (ChineneseparentData.length-1) && id > 0) && <div className="chart-line2 middle-line"></div>}

                                          {id == (ChineneseparentData.length-1) && <div className="chart-line2 end-line"></div>}

                                          <i class="chart-color fa-solid fa-arrow-down"></i>                                   
                                    <div className='members-info-block'>    
                                        <span className='members-info-details' style={{ margin: 10 }}>
                                            <h4>{childParentData?.attributes?.Title}</h4>
                                            {childParentData?.attributes?.MemberData.length ? childParentData?.attributes?.MemberData.map((parenData, index) => (
                                                parenData?.URLRedirection ?
                                                <Link key={index} to={parenData?.URLRedirection} target="_blank" style={{ color: '#620a0c' }}>   <p>{parenData?.Name}</p>
                                                    <p style={{ color: '#620a0c' }}>{parenData?.Designation == null ? null : "(" + parenData?.Designation + ")"}</p>
                                                </Link>
                                                :
                                                <div>
                                                <p style={{ color: '#620a0c' }}>{parenData?.Name}</p>
                                                <p style={{ color: '#620a0c' }}>{parenData?.Designation == null ? null : "(" + parenData?.Designation + ")"}</p>
                                                </div>

                                            )) : null}
                                        </span>

                                    </div>
                                </Tab>
                            )) : null}

                        </TabList>


                        <TabPanel>
                            <div className='tab-full-content-block'>
                                {NewOrganizationData_2.length > ChineneseparentData.length ?
                                    <div className='member-tab-content'>
                                        <div className='direction-path-full-width'>
                                        <i class="chart-color chart-arrow-up fa-solid fa-arrow-up"></i>
                                        </div>
                                    </div>
                                    : null}
                                <div className='member-inner-content'>
                                    {/* ==========================================member-row========================== */}
                                        
                                    {NewOrganizationData_2.length ? NewOrganizationData_2.map((chineseChildData, index) => (
                                        <div className='member-details-row' key={index}>
                                          {index == 0 && <div className="chart-line start-line"></div>}
                                          {(index < (NewOrganizationData_2.length-1) && index > 0) && <div className="chart-line middle-line"></div>}

                                          {index == (NewOrganizationData_2.length-1) && <div className="chart-line end-line"></div>}

                                          <i class="chart-color fa-solid fa-arrow-down"></i>                                           
                                            <div className='member-cetagory-info'>
                                                <h3>{chineseChildData?.attributes?.Title}</h3>
                                                {chineseChildData?.attributes?.MemberData.length ? chineseChildData?.attributes?.MemberData.map((membersData, index) => (
                                                    <Link key={index} to={membersData?.URLRedirection} target="_blank" style={{ color: '#620a0c' }}> {membersData?.Name}
                                                        <p style={{ color: '#620a0c' }}>{membersData?.Designation == null ? null : "(" + membersData?.Designation + ")"}</p>

                                                    </Link>)) : null}
                                            </div>

                                        </div>

                                    )) : null}

                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel>
                        <div className='tab-full-content-block-1'>
                                {NewOrganizationData_3.length > ChineneseparentData.length ?
                                    <div className='member-tab-content'>
                                        <div className='direction-path-full-width'>
                                        <i class="chart-color chart-arrow-up fa-solid fa-arrow-up"></i>
                                        </div>
                                    </div>
                                    : null}
                                <div className='member-inner-content'>
                                    {/* ==========================================member-row========================== */}

                                    {NewOrganizationData_3.length ? NewOrganizationData_3.map((chineseChildData, index) => (
                                        <div className='member-details-row' key={index}>      
                                          {index == 0 && <div className="chart-line start-line"></div>}
                                          {(index < (NewOrganizationData_3.length-1) && index > 0) && <div className="chart-line middle-line"></div>}

                                          {index == (NewOrganizationData_3.length-1) && <div className="chart-line end-line"></div>}

                                          <i class="chart-color fa-solid fa-arrow-down"></i>                                                                                  
                                            <div className='member-cetagory-info'>
                                                <h3>{chineseChildData?.attributes?.Title}</h3>
                                                {chineseChildData?.attributes?.MemberData.length ? chineseChildData?.attributes?.MemberData.map((membersData, index) => (
                                                    <Link key={index} to={membersData?.URLRedirection} target="_blank" style={{ color: '#620a0c' }}> {membersData?.Name}
                                                        <p style={{ color: '#620a0c' }}>{membersData?.Designation == null ? null : "(" + membersData?.Designation + ")"}</p>

                                                    </Link>)) : null}
                                            </div>

                                        </div>

                                    )) : null}

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                        <div className='tab-full-content-block-2'>
                                {NewOrganizationData_4.length > ChineneseparentData.length ?
                                    <div className='member-tab-content'>
                                        <div className='direction-path-full-width'>
                                        <i class="chart-color chart-arrow-up fa-solid fa-arrow-up"></i>
                                        </div>
                                    </div>
                                    : null}
                                <div className='member-inner-content'>
                                    {/* ==========================================member-row========================== */}

                                    {NewOrganizationData_4.length ? NewOrganizationData_4.map((chineseChildData, index) => (
                                        <div className='member-details-row' key={index}>  
                                          {index == 0 && <div className="chart-line start-line"></div>}
                                          {(index < (NewOrganizationData_4.length-1) && index > 0) && <div className="chart-line middle-line2"></div>}
                                          {index == (NewOrganizationData_4.length-1) && <div className="chart-line end-line"></div>}
                                          <i class="chart-color fa-solid fa-arrow-down"></i>                                                                                  

                                            <div className='member-cetagory-info'>
                                                <h3>{chineseChildData?.attributes?.Title}</h3>
                                                {chineseChildData?.attributes?.MemberData.length ? chineseChildData?.attributes?.MemberData.map((membersData, index) => (
                                                    <Link key={index} to={membersData?.URLRedirection} target="_blank" style={{ color: '#620a0c' }}> {membersData?.Name}
                                                        <p style={{ color: '#620a0c' }}>{membersData?.Designation == null ? null : "(" + membersData?.Designation + ")"}</p>

                                                    </Link>)) : null}
                                            </div>

                                        </div>

                                    )) : null}

                                </div>
                            </div>
                        </TabPanel>                        
                    </Tabs>
                </div>

            </section>

        </div>
    );
};

export default Members;