import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import './ContactUs.css';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import ContactServices from '../../services/Contact.service';
import { useLocation } from 'react-router-dom';


const ContactUs = () => {
    const contact_us_Slug = useLocation();
    const [contactData, setConatctData] = useState([]);
    const mapRef = useRef(null)
    const contact_Slug = contact_us_Slug.pathname.split('/')[1]

    useEffect(() => {
        ContactServices.getContact_Us(contact_Slug).then((response) => {
            const allContactData = response.data

            setConatctData(allContactData[0]['attributes'])
        })
    }, [contact_Slug])

    const { isLoaded, loadError } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBkM-Db5UncpwkGlz4E-plACyxcqF_mGTk",

    });

    const containerStyle = {
        width: '100%',
        height: '300px'
    };

    const center = {
        lat: 22.719568,
        lng: 75.857727
    };

    const options = {
        disableDefaultUI: true,
        zoomControl: true
    };


    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";


    return (
        <div>
            <section className='contact-section-block'>
                <div className='contact-section-inner'>
                    <h2>{contactData?.Title}</h2>

                    <div className='container contact-details'>
                        <div className='row'>

                            <div className='col-sm-12 col-lg-5'>
                                <div className='contact-info-block'>

                                    <p contentEditable='false' dangerouslySetInnerHTML={{ __html: contactData?.Content }} ></p>

                                    {/* <div className='contact-info'>
                                        <span className='contact-icon'> <FaEnvelope /></span>
                                        <span className='contact-text'><p>info@hkcmcrc.com</p></span>
                                    </div>
                                    <div className='contact-info'>
                                        <span className='contact-icon'> <FaPhoneAlt /></span>
                                        <span className='contact-text'><p>21761461</p></span>
                                    </div>
                                    <div className='contact-info'>
                                        <span className='contact-icon'> <FaMapMarkerAlt /></span>
                                        <span className='contact-text'><p>Rm815, Technological and Higher Education Institute of Hong Kong (Tsing Yi Campus),
                                            20A Tsing Yi Road, Tsing Yi Island, New Territories</p></span>
                                    </div> */}

                                </div>
                            </div>

                            <div className='col-sm-12 col-lg-7'>
                                <div className='map-section'>
                                    <div className='map-image'>
                                        {isLoaded ? (
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.42242965232!2d114.106419864805!3d22.34123192461496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403ffe82c198eb9%3A0x72f7de1411237da8!2zVEhFaSDpppnmuK_pq5jnrYnmlZnogrLnp5HmioDlrbjpmaIgKOmdkuiho-WIhuagoSk!5e0!3m2!1szh-TW!2shk!4v1682309064150!5m2!1szh-TW!2shk" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        ) : <></>}
                                        {/* <img src="../../images/map-image.png" /> */}
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ContactUs;
