import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AboutServices from '../../services/About.service';
import './AboutUs.css';

const AboutUs = () => {

    const about_us_Slug = useLocation();
    const [aboutData, setAboutData] = useState([]);
    const aboutParams = about_us_Slug.pathname.split('/')[1]

    useEffect(() => {

        AboutServices.getAbout_Us(aboutParams).then((response) => {
            const aboutData = response.data
            // console.log("aboutData", aboutData)
            if (aboutData) {
                setAboutData(aboutData[0]['attributes'])
            }

        })

    }, [])


    return (
        <div>
            <section className='about-us-section'>
                <div className='container'>
                    <div className='about-us-inner-block'>
                        <h2>{aboutData?.Title}</h2>
                    </div>
                    <div className='about-us-content-text'>
                        <p className='about-us-info' contentEditable='false' dangerouslySetInnerHTML={{ __html: aboutData?.Content }} />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;