import { useEffect, useState } from 'react';
import './App.css';
import Footer from './components/home/Footer';
import Header from './components/home/Header';
import Routing from './components/routing/Routing';
import LanguageHelper from './helper/LanguageHelper';

function App() {
  const [languages, setLanguage] = useState([]);

  useEffect(() => {
    //==========Get all language ===========
    const languages = LanguageHelper.getAllLanguage();
    //========Set defult language if not set language===== 
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('langNumber', 1);
      localStorage.setItem('lang', 'en');
    }

    //=========Here State Define==========
    setLanguage(languages);

  }, [])


  return (
    <div className="App">
      <Header dataFromApp ={languages} />
      <Routing />
      <Footer dataFromApp ={languages} />
    </div>
  );
}

export default App;
