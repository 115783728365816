import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();

//get the live api url functions
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
//get the language id functions
const language_id = ApiHelperData.userheadersData

//call the research achivement arrows functions and passing parameters
const getUsefulLinks = (usefulLinksParams) => {
  
    var useful_links_url;
    //check the conditions
    if (language_id.locale == "en") {
        useful_links_url = Live_API_URL + `articles?filters[slug][$eqi]=${usefulLinksParams}&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        useful_links_url = Live_API_URL + `articles?filters[slug][$eqi]=${usefulLinksParams}-tc&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        useful_links_url = Live_API_URL + `articles?filters[slug][$eqi]=${usefulLinksParams}-sc&locale=` + language_id.locale
    }

    return axios.get(useful_links_url)
        .then((response) => {
            console.log("useful_links_url", useful_links_url)
            console.log("response", response.data)
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}


//Exporting to the Useful Links Services
const UsefulLinksService = {
    getUsefulLinks
}
export default UsefulLinksService;