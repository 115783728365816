import React from 'react';
import './Footer.css';

const Footer = () => {
    return (

        <div>
            <footer>
                <section className='footer-section-block'>
                    <div className='footer-section-inner'>

                        <div className='footer-logo-block'>
                            <img src='/public/images/logo-white.svg'></img>
                        </div>
                        <div className='footer-text-info'>
                            <p>Copyright © 2023. All Rights Reserved.</p>
                            <p>Chinese Medicine & Culture Research Center</p>
                        </div>
                    </div>
                </section>
            </footer>
        </div>


    );
};

export default Footer;