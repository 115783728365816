import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();
// get Live api url data
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
// get language id data
const language_id = ApiHelperData.userheadersData


//calling the get about us arrows functions
const getContact_Us = (contact_Slug) => {
     var about_service_url;
    if (language_id.locale == "en") {
         about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${contact_Slug}&locale=` + language_id.locale
    }
    else if(language_id.locale == "zh-Hant-HK") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${contact_Slug}-tc&locale=` + language_id.locale
    }
    else if(language_id.locale == "zh-CN") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${contact_Slug}-sc&locale=` + language_id.locale
    }
  


    return axios.get(about_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//exporting the Contact Us Services
const ContactServices = {
    getContact_Us
}
export default ContactServices;