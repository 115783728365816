import axios from "axios";
import ApiHelper from "../helper/ApiHelper";

///call the api helper functions
const ApiHelperData = ApiHelper.getCMCRCData();
///get the live api url
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
///get the user headers api url
const language_id = ApiHelperData.userheadersData

//call the get about us api functions
const getPhotosAndVidoesApi = (photos_videos_data,gallery_Slug) => {
    var home_service_url;
    //check the conditons 
    // if (language_id.locale == "en") {
    //     home_service_url = Live_API_URL+photos_videos_data+"?populate=*&filters[Gallery][id][$eq]="+gallery_Slug+"&locale=" + language_id.locale
    // }
    // else if (language_id.locale == "zh-Hant-HK") {
    //     home_service_url = Live_API_URL+photos_videos_data+"?populate=*&locale=" + language_id.locale
    // }
    // else if (language_id.locale == "zh-CN") {
    //     home_service_url = Live_API_URL+photos_videos_data+"?populate=*&locale=" + language_id.locale
    // }
    // else {
    //     home_service_url = Live_API_URL+"populate=*"
    // }

    home_service_url = Live_API_URL+photos_videos_data+"?populate=*&filters[Gallery][id][$eq]="+gallery_Slug+"&locale=" + language_id.locale


    return axios.get(home_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//Exporting Home Services
const PhotosAndVideosServices = {
    getPhotosAndVidoesApi
}
export default PhotosAndVideosServices;