import React, { useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import HerbalGardenService from '../../services/Herbal.service';
import './HerbalGarden.css';

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const HerbalGarden = () => {
    const [allherbalData, setHerbalGarden] = useState([])
    const [allplantData, setPlantsData] = useState([]) 
    const { t } = useTranslation()
    const herbal_Slug = useLocation()
    const herbalParams = herbal_Slug.pathname.split('/')[1]
    const [zone1, setZone1] = useState();
    const [zone2, setZone2] = useState();
    const [zone3, setZone3] = useState();
    const [zone4, setZone4] = useState();
    const [zone5, setZone5] = useState();
    const [zone6, setZone6] = useState();
    const [zone7, setZone7] = useState();
    const IframeUrl = "https://hkcmcrc.com/plants";
    const [iframSrc, setIframeUrl] = useState(IframeUrl);
  
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        HerbalGardenService.getHerbal_Garden(herbalParams).then((response) => {
            const allherbal_GardenData = response.data[0]
            setHerbalGarden(allherbal_GardenData)
        });
        HerbalGardenService.getAllPlantsData_ZoneWise().then((response) => {
          const allplant_Data = response.data;
          setPlantsData(allplant_Data);
        });        
    }, [])

    const handlerModal = (getting_Pid, lang) => {
      setIframeUrl(
        "https://hkcmcrc.com/plants/index.php?" +
          `lang=${lang}&item=${getting_Pid}`
      );
    };


    const clickHandler = (str) => () => {
        setZone1(false);
        setZone2(false);
        setZone3(false);
        setZone4(false);
        setZone5(false);
        setZone6(false);
        setZone7(false);        
        switch(str){
            case 1:
                setZone1(true);
                break;
            case 2:
                setZone2(true);
                break;    
            case 3:
                setZone3(true);
                break;
            case 4:
                setZone4(true);
                break;   
            case 5:
                setZone5(true);
                break;
            case 6:
                setZone6(true);
                break;    
            case 7:
                setZone7(true);
                break;                                        

        }
      };

    // const clickHandler = () => {
    // 	setZone1(!zone1);
  	// }

    return (
        <div>

        <Modal name="plantModal" open={open} onClose={onCloseModal} center>
            
	<iframe class="embed-responsive-item plantIframe" src={iframSrc} width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Modal>

            <section className='achievement-section-block'>
                <div className='herbal-section-inner'>
                    {/* <div className='herbal-back-button'>
                         <a href='/herbal-garden'>Back</a>
                    </div> */}
                    <h2>{allherbalData?.attributes?.Title}</h2>
                    <div className='container'>
                        <div className='herbal-info-text'>
                            <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.Content }} />
                        </div>
                        <br />
                        <div className='map'>
                            <h2 style={{textAlign: "left"}}>{t('maptitle')}</h2>
                            
                            <figure id='backing'>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1280 700" preserveAspectRatio="xMinYMin meet">
                                    <image width="1200" height="700" xlinkHref="/uploads/map.png"></image>
                                    <image xlinkHref={zone6 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="930" y="140" width="40" height="55" class="zone zone6" cursor="pointer" id="zone6" onClick={clickHandler(6)} >
                                                                                 <animateTransform attributeName="transform"
                                        type="translate"
                                        from="0 0" to="0 10"
                                        begin="0s" dur="1s"
                                        repeatCount="indefinite"
                                        />
                                    </image>                                                                        
                                    <image xlinkHref={zone4 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="680" y="150" width="40" height="55" class="zone zone4" cursor="pointer" onClick={clickHandler(4)}>
                                        <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                      
                                    </image>
                                    <image xlinkHref={zone5 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="730" y="340" width="40" height="55" class="zone zone5" cursor="pointer" onClick={clickHandler(5)}>
                                    <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                         
                                    </image>
                                    <image xlinkHref={zone7 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="820" y="240" width="40" height="55" class="zone zone7" cursor="pointer" onClick={clickHandler(7)}>
                                        <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                         
                                    </image>
                                    <image xlinkHref={zone3 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="400" y="250" width="40" height="55" class="zone zone3" cursor="pointer" onClick={clickHandler(3)}>
                                        <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                         
                                    </image>
                                    <image xlinkHref={zone2 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="250" y="350" width="40" height="55" class="zone zone2" cursor="pointer" onClick={clickHandler(2)}>
                                        <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                         
                                    </image>
                                    <image xlinkHref={zone1 ? "/uploads/red-pin.png" : "/uploads/pin.png" } x="280" y="500" width="40" height="55" class="zone zone1" cursor="pointer" onClick={clickHandler(1)}>
                                        <animateTransform attributeName="transform"
                                            type="translate"
                                            from="0 0" to="0 10"
                                            begin="0s" dur="1s"
                                            repeatCount="indefinite"
                                        />                                         
                                    </image>
                                </svg>
                            </figure>
                            {
                                (zone1 == null || zone1) && 
                                <div className='zone1'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone1 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5>                                
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;
                                        return (
                                          (zone == 1) && 
                                          <li key={i} className="plantItem">                                           
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} /> */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>
                                    <br />
                                </div>  
                            }
                            {
                                (zone2 == null || zone2) && 
                                <div className='zone2'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone2 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5>                                     
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 2 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} /> */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>  
                                    <br />                                  
                                </div>     
                            }
                            {
                                (zone3 == null || zone3) &&                            
                                <div className='zone3'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone3 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5> 
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 3 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} />                                               */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>   
                                    <br />                                 
                                </div>
                            }
                            {
                                (zone4 == null || zone4) &&                                                        
                                <div className='zone4'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone4 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5> 
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 4 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} />                                               */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>       
                                    <br />                              
                                </div>    
                            }
                            {
                                (zone5 == null || zone5) &&                             
                                <div className='zone5'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone5 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5> 
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 5 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} />                                               */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>      
                                    <br />                               
                                </div>  
                            }
                            {
                                (zone6 == null || zone6) &&                            
                                <div className='zone6'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone6 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5> 
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 6 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} />                                               */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>       
                                    <br />                              
                                </div>  
                            }
                            {
                                (zone7 == null || zone7) &&                             
                                <div className='zone7'>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: allherbalData?.attributes?.zone7 }} />
                                    <h5 class="plantInfoTitle">
                                    {t("plantItemTitle")}
                                    </h5> 
                                    <ul className="plantHolder">
                                      {allplantData.map((value, i) => {
                                        const zone = allplantData[i]?.attributes?.zone;

                                        return (
                                          zone == 7 && 
                                          <li key={i} className="plantItem">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                 handlerModal(allplantData[i]?.attributes?.Pid, allplantData[i]?.attributes?.locale);
                                                 onOpenModal();
                                              }}
                                            >
                                            {/* <img className='circlePlant' src={`${allplantData[i]?.attributes?.Gallery.data && allplantData[i]?.attributes?.Gallery.data[0].attributes.url}`} />                                               */}
                                            {allplantData[i]?.attributes?.Title}
                                            </button>
                                          </li>
                                        );                                        
                                      })}
                                    </ul>       
                                    <br />                              
                                </div>   
                            }                                
                        </div>                                                                                                                                                              
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HerbalGarden;
