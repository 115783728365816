//call the get all language arrows functions
const getAllLanguage = () => {

    //defining the  language array
    const languages = [
        {
            // languageName: 'Simplified Chinese',

            languageName: '简',
            languageSortName: "zh-CN",
            language: 'zh-CN',
            languageNumber: 3
        },
        {
            // languageName: 'Traditional Chinese',

            languageName: '繁',
            languageSortName: "ZH",
            language: 'zh-Hant-HK',
            languageNumber: 2,
        },
        {
            // languageName: 'English',

            languageName: 'EN',
            languageSortName: "EN",
            language: 'en',
            languageNumber: 1,
        }
    ];
    return languages;
};
//Exporting the Language helper 
const LanguageHelper = {
    getAllLanguage
}
export default LanguageHelper;
