import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AchievementService from '../../services/Achievement.service';
import './Achievement.css';

const Achievement = () => {
    const [achievementData, setAchievementData] = useState([])
    const achivement_us_Slug = useLocation();
    const AchievementParams = achivement_us_Slug.pathname.split('/')[1]
    // console.log("achievementData",achievementData)

    useEffect(() => {
        AchievementService.getResearch_Achievement(AchievementParams).then((response) => {
            const achievementData = response.data
            if(achievementData)
            {
                setAchievementData(achievementData[0]['attributes'])
            }
           
        })
    }, [AchievementParams])



    return (
        <div>
            <section className='achievement-section-block'>
                <div className='achievement-section-inner'>
                    <h2>{achievementData?.Title}</h2>
                    <div className='container'>
                        <div className='achievement-info-text'>

                        <p contentEditable='false' dangerouslySetInnerHTML={{ __html: achievementData?.Content}} />

                            {/* <div className='info-heading-text'>
                                <p>1. On going research</p>
                            </div>

                            <p>A. 10 student final year projects involving 35 students, research topics:,</p>

                            <p>i. Possible Samples Development by using the herbs from eco-farm (herbal bath, Suanzao cake, herbal tea)</p>
                            <p>ii. CM authentication in HK market</p>
                            <p>iii. Clinical study of Lingnan herbal medicines (Shanzha、Shanyao、Tufulin)</p>
                            <p>iv. Chinese medicine residues</p>
                            <p>v. Tie-dye technology and culture by using natural herbal dyes</p>
                            <p>vi. Horicultural Therapy</p>
                            <p>vii. Beauty/skin care product development by using Lingnan herbs (lotus seed cover)</p>
                            <p>viii. Application and development of handheld NIR spectrometer to identify the maturity of hydroponic Lingnan herbs</p>
                            <p>ix. Nano technology for skin care products</p>
                            <p>x. Antibacterial property of hydrophobic Anredera cordifolia, Taraxacum mongolicum and Portulaca oleracea</p>

                            <div className='info-heading-text'>
                                <p>2. Paper Publication:</p>
                            </div>

                            <p>Siu Kan LAW, Dawn Ching Tung AU, Wesley Yeuk Lung CHOW & Yanping WANG (2022), Establishment of a Sustainable Management Model for Chinese Herbal Garden in an Urban City - Hong Kong, Sustainability 2022, Volume 14 , Issue 23</p> */}

                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default Achievement;
