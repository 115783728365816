import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../home/Home';
import AboutUs from '../pages/AboutUs';
import Achievement from '../pages/Achievement';
import ContactUs from '../pages/ContactUs';
import Gallery from '../pages/Gallery';
import HerbalGarden from '../pages/HerbalGarden';
import Members from '../pages/Members';
import Newsdetails from '../pages/Newsdetails';
import NewsAndEvents from '../pages/News_and_Events';
import Videosdetails from '../pages/Videosdetails';
import WhiskersChineseHerbalDataBase from '../pages/WhiskersChineseHerbalDataBase';
import UsefulLinks from '../pages/UsefulLinks';
import Nopage from './Nopage';

///calling the routing functions and defining all the routes 
const Routing = () => {

    return (
        <Routes>
            <Route index element={<Home />} ></Route>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/about-us' element={<AboutUs />} />
            <Route exact path='/news-and-events' element={<NewsAndEvents />} />
            <Route exact path='/news-and-events/:slug' element={<Newsdetails />} />
            <Route exact path='/research-achievement' element={<Achievement />} />
            <Route exact path='/photos-and-videos/:slug' element={<Videosdetails />} />
            <Route exact path='/members' element={<Members />} />
            <Route exact path='/contact-us' element={<ContactUs />} />
            <Route exact path='/galleries' element={<Gallery />} />
            <Route exact path='/:slug' element={<HerbalGarden />} /> 
            <Route exact path='/whiskers_chinese_herbal_data_base' element={<WhiskersChineseHerbalDataBase />} />
            <Route exact path='/useful_links' element={<UsefulLinks />} />
            <Route exact path="*" element={<Nopage />} />
        </Routes>
    );
};

export default Routing;