import axios from "axios";
import ApiHelper from "../helper/ApiHelper";

///call the api helper functions
const ApiHelperData = ApiHelper.getCMCRCData();
///get the live api url
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
///get the user headers api url
const language_id = ApiHelperData.userheadersData

//call the get about us api functions
const getHomeApi = async () => {
    var home_service_url;
    //check the conditons 
    if (language_id.locale == "en") {
        home_service_url = Live_API_URL+"homepage?populate[Slider][populate]=*&populate[AboutUs][populate]=*&populate[NewsandEvents][populate][news_and_events][populate]=*&populate[Partnerships][populate]=*&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        home_service_url = Live_API_URL+"homepage?populate[Slider][populate]=*&populate[AboutUs][populate]=*&populate[NewsandEvents][populate][news_and_events][populate]=*&populate[Partnerships][populate]=*&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        home_service_url = Live_API_URL+"homepage?populate[Slider][populate]=*&populate[AboutUs][populate]=*&populate[NewsandEvents][populate][news_and_events][populate]=*&populate[Partnerships][populate]=*&locale=" + language_id.locale
    }


    return await axios.get(home_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

const getMainMenuApi = async () =>{
    var main_menu_service_url;
    //check the conditons 
    if (language_id.locale == "en") {
        main_menu_service_url = Live_API_URL+"main-menus?sort[0]=Order%3Aasc&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        main_menu_service_url = Live_API_URL+"main-menus?sort[0]=Order%3Aasc&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        main_menu_service_url = Live_API_URL+"main-menus?sort[0]=Order%3Aasc&locale=" + language_id.locale
    }


    return await axios.get(main_menu_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//Exporting Home Services
const HomeServices = {
    getHomeApi,
    getMainMenuApi
}
export default HomeServices;