import React, { useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import UsefulLinksService from "../../services/UsefulLinks.service";
import './UsefulLinks.css';

import "react-responsive-modal/styles.css";

const UsefulLinks = () => {
  const [allUsefulLinks, setUsefulLinks] = useState([]);
  const usefullinks_Slug = useLocation();
  const usefulLinksParams = usefullinks_Slug.pathname.split("/")[1];
  
  useEffect(() => {
    UsefulLinksService.getUsefulLinks(usefulLinksParams).then((response) => {
      const allUsefulLinks = response.data[0];
      setUsefulLinks(allUsefulLinks);
    });
  }, []);

 
  return (
    
    <div>
     
      
      <section className="achievement-section-block">
        <div className="herbal-section-inner">
          <h2>{allUsefulLinks?.attributes?.Title}</h2>
          <div className="container">
            <div className="herbal-info-text">
              <div
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: allUsefulLinks?.attributes?.Content,
                }}
              />
            </div>
            <br />
            
          
          </div>
        </div>
      </section>
    </div>
  );
};

export default UsefulLinks;

