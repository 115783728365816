import React from 'react';
import ReactLoading from 'react-loading';
import './Loader.css';

export const Loader = () => {
    return (
        <div className='loader-block'>
            <div>
                <ReactLoading className="loader" type='spin' color={'#05003C'} height={'3%'} width={'3%'} />
            </div>
        </div>

    )
};
