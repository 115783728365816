import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();
//get live url data 
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
//get language id data 
const language_id = ApiHelperData.userheadersData


//call the news and event get api and passing parameters
//and news and event list api 
const getNews_and_Events = (news_event_data) => {
    var news_event_service_url;
    ///check the conditions
    if (language_id.locale == "en" && news_event_data == "news-and-events") {
        news_event_service_url = Live_API_URL + news_event_data + "?locale=" + language_id.locale + "&populate=*&sort[0]=Order%3Adesc"
    }
    else if (language_id.locale == "zh-Hant-HK" && news_event_data == "news-and-events") {
        news_event_service_url = Live_API_URL + news_event_data + "?locale=" + language_id.locale + "&populate=*&sort[0]=Order%3Adesc"
    }
    else if (language_id.locale == "zh-CN" && news_event_data == "news-and-events") {
        news_event_service_url = Live_API_URL + news_event_data + "?locale=" + language_id.locale + "&populate=*&sort[0]=Order%3Adesc"
    }

    return axios.get(news_event_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//call the news and event get api and passing parameters
//and news and event details api 
const getNews_Events_Details = (news_event_data, news_event_id) => {

    let news_service_url;
    //making the routing url
    const details_Slug = news_event_data + "?filters[slug][$eqi]=" + news_event_id
    //check the conditions

    if (language_id.locale == "en") {
        news_service_url = Live_API_URL + details_Slug + "&populate=*&locale=" + language_id.locale

    }
    else if (language_id.locale == "zh-Hant-HK") {
        news_service_url = Live_API_URL + details_Slug + "&populate=*&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        news_service_url = Live_API_URL + details_Slug + "&populate=*&locale=" + language_id.locale
    }


    return axios.get(news_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}


///exporting news and Event Services
const NewsAndEventsServices = {
    getNews_and_Events,
    getNews_Events_Details
}
export default NewsAndEventsServices;