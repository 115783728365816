import React, { useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import WhiskersChineseHerbalDataBaseService from "../../services/WhiskersChineseHerbalDataBase.service";
import './WhiskersChineseHerbalDataBase.css';

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const WhiskersChineseHerbalDataBase = () => {
  const [allChineseherbalDataBase, setChineseHerbalDataBase] = useState([]);
  const { t } = useTranslation();
  const herbal_Slug = useLocation();
  const herbalParams = herbal_Slug.pathname.split("/")[1];
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    WhiskersChineseHerbalDataBaseService.getChineseHerbal_Database(herbalParams).then((response) => {
      const allChineseherbal_Database = response.data[0];
      setChineseHerbalDataBase(allChineseherbal_Database);
    });
  }, []);

 
  return (
    
    <div>
     
      
      <section className="achievement-section-block">
        <div className="herbal-section-inner">
          <h2>{allChineseherbalDataBase?.attributes?.Title}</h2>
          <div className="container">
            <div className="herbal-info-text">
              <div
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: allChineseherbalDataBase?.attributes?.Content,
                }}
              />
            </div>
            <br />
            
          
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhiskersChineseHerbalDataBase;

