import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();

//get the live api url functions
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
//get the language id functions
const language_id = ApiHelperData.userheadersData

//call the research achivement arrows functions and passing parameters
const getResearch_Achievement = (AchievementParams) => {
    var about_service_url;
    //check the conditions
    if (language_id.locale == "en") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${AchievementParams}&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${AchievementParams}-tc&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        about_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${AchievementParams}-sc&locale=` + language_id.locale
    }

    return axios.get(about_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}
//Exporting to the Research and Achivement Services
const AchievementService = {
    getResearch_Achievement
}
export default AchievementService;