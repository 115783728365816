
import React, { useEffect, useState } from 'react';
import './Videosdetails.css';
// import "react-lightbox-pack/dist/index.css";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import PhotosAndVideosServices from '../../services/Photos_and_videos.service';
import ApiHelper from '../../helper/ApiHelper';
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment';
import ReactPlayer from 'react-player';
import { Loader } from './Loader';



const Videosdetails = () => {

    const photos_and_videos_Slug = useLocation();
    const gallery_Slug = photos_and_videos_Slug.pathname.split('/')[2];
    const photos_videos_Params = photos_and_videos_Slug.pathname.split('/')[1]
    ///call the api helper functions
    const ApiHelperData = ApiHelper.getCMCRCData()
    ///get the live Image api url
    const Live_API_Images_URL = ApiHelperData.Live_Image_Url

    // const [showModal, setShowModal] = useState(false);
    const [Image_Vidoes_Id, setModel_ID] = useState(0)

    // const modalRef = useRef();

    const openModal = (model_id) => {
        setModel_ID(model_id)
        console.log("model_id", model_id)
        window.scrollTo(0, 0)
    };

    // const closeModal = (e) => {
    //     if (e.target === modalRef.current) {
    //         setShowModal(false);
    //     }
    // };

    const [allphotosandvidoesData, setPhotosAndVideos] = useState([])
    const [allphotoAndVideoData, setData] = useState([]);
    //set the initial value of perpage
    const [perPage] = useState(10);
    //set the initial page count Value
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true)


    var ImagesData = [];
    // var VidoesData = [];

    for (var i = 0; i < allphotosandvidoesData.length; i++) {
        if (allphotosandvidoesData[i]?.attributes?.Type == "Photo") {
            let imageUrlObj = { id: i, image: Live_API_Images_URL + allphotosandvidoesData[i]?.attributes?.Media?.data[0].attributes?.url }
            ImagesData.push(imageUrlObj)
        }
        else {

            // const making_Videos_Url = (allphotosandvidoesData[i]?.attributes?.URL).split('=')[1]
            // const Videos_url = "https://img.youtube.com/vi/" + making_Videos_Url + "/hqdefault.jpg"
            let imageUrlObj = { id: i, type: "video", video: allphotosandvidoesData[i]?.attributes?.URL }
            ImagesData.push(imageUrlObj)
            // VidoesData.push(Videos_url)
        }
    }

    // console.log("ImagesData",ImagesData)
    // console.log("VidoesData",VidoesData)

    useEffect(() => {
        setLoading(true)
        PhotosAndVideosServices.getPhotosAndVidoesApi(photos_videos_Params, gallery_Slug).then((response) => {
            const photosandVidoesData = response.data
            setLoading(false)
            setPhotosAndVideos(photosandVidoesData)
            const data = photosandVidoesData.slice(0, perPage);
            setPageCount(Math.ceil(photosandVidoesData.length / perPage));
            setData(data);

        })
    }, [])



    //call the event handler for page clicked
    const handlePageClick = (data) => {
        //===count the current page=============
        const currentpage = data.selected;
        //increament the current page value and multiply by the perpages
        const endRange = (currentpage + 1) * perPage;
        //decrement the value of end range and perpage
        const startRange = endRange - perPage
        //set the data according to your paginations 
        setData(allphotosandvidoesData.slice(startRange, endRange));
        // //scroll in the top of the page
        window.scrollTo(0, 0);
    }
    //https://img.youtube.com/vi/7xQx2jsJUDM/hqdefault.jpg

    const Righthandler = (model_id) => {
        setModel_ID(model_id)
    }
    const Lefthandler = (model_id) => {
        setModel_ID(model_id)
    }

    return (
        <div>
            <section className='videos-block-section'>
                <div className='videos-and-photos-loader'>
                    <div className='loder-block'>
                        {loading && <Loader />}
                    </div>
                </div>
                <div className='video-back-button'>
                    <Link to='/galleries'>Back</Link>
                </div>
                <div className='videos-setion-inner'>

                    <div className='modal-block'>
                        <div className="box">
                            <div className='container'>

                                <div className='row'>
                                    {allphotoAndVideoData.map((photosandvidoesData, index) => (
                                        <>
                                            <div className='col-lg-4 col-md-6 col-12  videos-block' key={index}>
                                                <div>
                                                    {photosandvidoesData?.attributes?.Type == "Photo" ?
                                                        <a className="button-click" href="#photos">
                                                            <button onClick={() => openModal(index + 1)} className="videos-button">
                                                                <div className='videos-image-block' style={{ backgroundImage: "url(" + Live_API_Images_URL + photosandvidoesData?.attributes?.Media?.data[0]?.attributes?.url + ")", maxHeight: "100%", maxWidth: "100%" }}>
                                                                </div>
                                                            </button>
                                                        </a>

                                                        :
                                                        <a className="button-click" href="#photos" >
                                                            <button onClick={() => openModal(index + 1)} className="videos-button">
                                                                <div className='videos-image-block' style={{ backgroundImage: "url(" + "https://img.youtube.com/vi/" + photosandvidoesData?.attributes?.URL.split('=')[1] + "/hqdefault.jpg" + ")", maxHeight: "100%", maxWidth: "100%" }}>
                                                                </div>
                                                            </button>
                                                        </a>
                                                    }
                                                </div>
                                                {/* <div className='image-description'>
                                                    <h5>{photosandvidoesData?.attributes?.Title}</h5>
                                                    <p className='image-date'>{moment(photosandvidoesData?.attributes?.createdAt).format("DD MMM YYYY")}</p>
                                                </div> */}
                                            </div>
                                        </>
                                    ))}

                                    <div id="photos" className="overlay">
                                        <div className="popup">
                                            <a className="close" href="#">&times;</a>
                                            <div className="content">
                                                <div className='modal-content-inner'>
                                                    {allphotoAndVideoData[Image_Vidoes_Id - 1]?.attributes?.Type == "Photo" ?

                                                        <div className=''>
                                                            <img
                                                                key={allphotoAndVideoData[Image_Vidoes_Id - 1]?.id}
                                                                src={Live_API_Images_URL + allphotoAndVideoData[Image_Vidoes_Id - 1]?.attributes?.Media?.data[0]?.attributes?.url}
                                                                style={{ maxHeight: "100%", maxWidth: "100%" }}
                                                                alt={allphotoAndVideoData[Image_Vidoes_Id - 1]?.attributes?.Alt}
                                                            />

                                                        </div>
                                                        :

                                                        <div className='set-video-model-blocks'>
                                                            <div className='set-video-model-backgorund' style={{ width: '100vw', height: '500vh' }}>

                                                                <ReactPlayer url={allphotoAndVideoData[Image_Vidoes_Id - 1]?.attributes?.URL}
                                                                    config={{
                                                                        playerVars: { disablekb: 0 }
                                                                    }}
                                                                    light={false}
                                                                    playing={false}
                                                                    onContextMenu={(e) => e.preventDefault()}
                                                                    onDisablePIP={false}
                                                                    pip={true}
                                                                    showinfo={'0'}
                                                                    className="video-react-player"
                                                                    initialPlayerParams={{ controls: false }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='next-prev-icon-block'>
                                            <div className='model-photo button-model-block'>
                                                <button style={{ position: 'relative' }} onClick={() => Lefthandler(Image_Vidoes_Id - 1 > 0 ? Image_Vidoes_Id - 1 : 1)} className="left-angle-button"><FaAngleLeft /></button>
                                                <button style={{ position: 'relative' }} onClick={() => Righthandler(Image_Vidoes_Id + 1 <= allphotoAndVideoData.length ? Image_Vidoes_Id + 1 : 1)} className="right-angle-button"><FaAngleRight /></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pagination-block'>
                        <div className='pagination-content-inner'>
                            <ReactPaginate
                                nextLabel=<FaAngleRight />
                                previousLabel=<FaAngleLeft />
                                breakLabel={'...'}
                                onPageChange={handlePageClick}
                                pageCount={pageCount >= 1 ? pageCount : pageCount}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                containerClassName="pagination pagination-playerinformation"
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}

                            />
                        </div>
                    </div>
                </div>
            </section>
            <div></div>
        </div>
    );
};

export default Videosdetails;

