import React from 'react';

//call the nopage functions
const Nopage = () => {
    return (
        <div>
              <img alt="cmcrc" src='/public/images/404NotFound.png' className='w-50' />
        </div>
    );
};

export default Nopage;