import React, { useEffect, useState } from 'react';
import './Header.css';
import i18n from '../../i18n';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HomeServices from '../../services/Home.service';

const Header = (props) => {
    //fetching the data of from app page 
    const { dataFromApp } = props
    //set the language slug and call the useState hooks
    const [lang, setLanguage] = useState(0)
    //set the language Number and call the useState hooks
    const [languageNumber, setLanguageNumber] = useState()
    //destructing the t object and  call the usetranslations hooks
    const { t } = useTranslation()
    const [mainMenuData, setAllMainMenuData] = useState([])

    useEffect(() => {

        HomeServices.getMainMenuApi().then((response) => {
            const allmainMenuData = response.data
            setAllMainMenuData(allmainMenuData)
        })

        // converting string Value to integer
        var langNumber = parseInt(localStorage.getItem('langNumber'))
        //get the language slug 
        var lang = localStorage.getItem('lang')

        //check the conditions
        if (!lang) {
            //set the chinese languages
            lang = "zh-Hant-HK";
            // call the set language slug functions
            setLanguage({ lang });
            //call the set language number functions
            setLanguageNumber(langNumber)
        } else if (!lang) {
            //set the chinese languages
            lang = "zh-CN";
            // call the set language slug functions
            setLanguage({ lang });
            //call the set language number functions
            setLanguageNumber(langNumber)
        }
        else {
            // call the set language slug functions
            setLanguage({ lang });
            //call the set language number functions
            setLanguageNumber(langNumber)
        }

    }, [])

    //call the change Language event handler
    const changeLanguage = (languageNumber, lang) => {
        //set the language number  and language slug
        localStorage.setItem('langNumber', languageNumber);
        localStorage.setItem('lang', lang);
        //call the translate language fucntions 
        i18n.changeLanguage(lang)
        // call the reload fucntions for reload the page 
        window.location.reload();
    }
    // const  [loopIndex, setIoopIndex] = useState(0)
    let loopIndex = false;

    return (

        <div>
            <section className='header-block'>
                <header>
                    <div className='logo-block'>
                        <span className='logo-image'><a href='/'><img src='/public/images/logo.svg' alt='logo' className='logo-img' /></a></span>

                        <div className='language-info'>
                            {dataFromApp.map((language, index) => (
                                languageNumber != 3 ? (index == 1 ? loopIndex = true : null) : (index == 1 ? loopIndex = false : loopIndex = true),

                                language.languageNumber !== languageNumber ?
                                    <div key={index} onClick={() => changeLanguage(language.languageNumber, language.language)} className='language-info-inner'>
                                        {language.languageNumber == languageNumber} <p className={language.languageNumber === lang ? 'font-12' : 'text_gray ml-2'} style={{ color: "#630b09" }}>{loopIndex ? "|" : null} {loopIndex}{language.languageName} </p>
                                    </div>
                                    : null

                            ))}

                            {/* setIoopIndex(loopIndex + 1) */}
                            {/* loopIndex =  */}
                            {/* {loopIndex%2==0 ? "|" : null} */}


                            {/* {
                                languageNumber !== 2 ?
                                    <div onClick={() => changeLanguage(dataFromApp[0]?.languageNumber, dataFromApp[0]?.language)} className='language-info-inner'>
                                        <p className={dataFromApp[0]?.languageNumber === lang ? 'font-12' : 'text_gray ml-2'}><img src='https://hkcmcrc.com/public/images/china-flag.png' alt='logo' /> {dataFromApp[0]?.languageSortName}</p>
                                    </div>
                                    :
                                    languageNumber !== 1 ?
                                        <div onClick={() => changeLanguage(dataFromApp[1]?.languageNumber, dataFromApp[1]?.language)} className='language-info-inner'>
                                            <p className={dataFromApp[1]?.languageNumber === lang ? 'font-12' : 'text_gray ml-2'}><img src='https://hkcmcrc.com/public/images/english-flag.png' alt='logo' />{dataFromApp[1]?.languageSortName}</p>
                                        </div>
                                        : null} */}


                        </div>


                    </div>

                    <div className='nav-section'>
                        <div className='container header-custom-width'>
                            <nav className='menu-block'>
                                <ul>
                                    {mainMenuData.length ? mainMenuData.map((mainMenuData, index) => (
                                        <li key={index}><Link to={mainMenuData?.attributes?.Slug}>{mainMenuData?.attributes?.Title}</Link></li>
                                    )) : null}

                                    {/* <li><Link to='/'>{t('home')}</Link></li>
                                    <li><Link to='/about-us'>{t('about_us')}</Link></li>
                                    <li><Link to='/members'>{t('members')}</Link></li>
                                    <li><Link to='/news-and-events'>{t('news_and_event')}</Link></li>
                                    <li><Link to='/research-achievement'>{t('research_achievement')}</Link></li>
                                    <li><Link to='/galleries'>{t('videos_and_photos')}</Link></li>
                                    <li><Link to='/contact-us'>{t('contact_us')}</Link></li>
                                    <li><a href='/herbal-garden'>{t('herbal_garden')}</a></li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className='mobile-menu-section'>
                        <input type="checkbox" id="menu" />
                        <label htmlFor="menu" className="menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>

                        <nav className="nav">
                            <ul>
                                {mainMenuData.length ? mainMenuData.map((mainMenuData, index) => (
                                        <li key={index}><Link to={mainMenuData?.attributes?.Slug}>{mainMenuData?.attributes?.Title}</Link></li>
                                    )) : null}
                                {/* <li><Link to='/'>{t('home')}</Link></li>
                                <li><Link to='/about-us'>{t('about_us')}</Link></li>
                                <li><Link to='/members'>{t('members')}</Link></li>
                                <li><Link to='/news-and-events'>{t('news_and_event')}</Link></li>
                                <li><Link to='/research-achievement'>{t('research_achievement')}</Link></li>
                                <li><Link to='/galleries'>{t('videos_and_photos')}</Link></li>
                                <li><Link to='/contact-us'>{t('contact_us')}</Link></li>
                                <li><Link to='/herbal-garden'>{t('herbal_garden')}</Link></li> */}
                            </ul>
                        </nav>
                    </div>
                </header>
            </section>
        </div>
    );
};


export default withTranslation('translation')(Header);