import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import ApiHelper from '../../helper/ApiHelper';
import NewsAndEventsServices from '../../services/NewsAndEvents.service';
import './Newsdetails.css';

const Newsdetails = () => {
  //set the all news details data
  const [allnewseventDetailsData, set_news_and_event_Details] = useState([])
  //call the uselocations hooks for getting url paths
  const news_details_Slug = useLocation();
  //get the language slug
  const language_id = localStorage.getItem('lang')
  //call use Navigate hooks for navigate
  const navigate = useNavigate();

  //making of url
  var news_event_Slug = news_details_Slug.pathname.split('/')[1]
  var news_details_Id = news_details_Slug.pathname.split('/')[2]

  ///call the api helper functions
  const ApiHelperData = ApiHelper.getCMCRCData()
  ///get the live Image api url
  const Live_API_URL = ApiHelperData.Live_Image_Url

  const Live_API_Images_URL = ApiHelperData.Live_Image_Url

  // const [showModal, setShowModal] = useState(false);
  const [Image_Vidoes_Id, setModel_ID] = useState(0)

  // const modalRef = useRef();

  const openModal = (model_id) => {
      setModel_ID(model_id)
      console.log("model_id", model_id)
      window.scrollTo(0, 0)
  };


  //call the useEffect hooks
  useEffect(() => {

    //call news details api
    NewsAndEventsServices.getNews_Events_Details(news_event_Slug, news_details_Id).then((response) => {
      //gettting the response of news details api
      const alllocalizationDetailsData = response.data[0]
      //checking the conditions 
      if (language_id == alllocalizationDetailsData?.attributes?.locale) {
        //call set_news_and_event_Details functions for set the values
        set_news_and_event_Details(alllocalizationDetailsData);
        //set the news slug and news localizations language in localStorage 
        //set the data of localizations of first index
        localStorage.setItem("News_Slug", alllocalizationDetailsData?.attributes?.localizations?.data[0].attributes?.slug)
        localStorage.setItem("News_Locale", alllocalizationDetailsData?.attributes?.localizations?.data[0].attributes?.locale)

        //set the news slug and news localizations language in localStorage 
        //set the data of localizations of second index
        localStorage.setItem("News_Slug_2", alllocalizationDetailsData?.attributes?.localizations?.data[1].attributes?.slug)
        localStorage.setItem("News_Locale_2", alllocalizationDetailsData?.attributes?.localizations?.data[1].attributes?.locale)

      }
      else {
        //get the value of news localization first values 
        const News_locale = localStorage.getItem('News_Locale')
        //gettting the news slug
        const News_locale_2 = localStorage.getItem('News_Locale_2')
        //navigate to the page according to your passing as slug
        //check the conditions of first index values
        if (language_id == News_locale) {
          //gettting the news slug
          const News_Slug = localStorage.getItem('News_Slug')
          navigate("/news-and-events/" + News_Slug);
          //remove localStorage news slug
          localStorage.removeItem("News_Slug")
        }
        //check the conditiions of second index values
        else if (language_id == News_locale_2) {
          //get the value of news localization second values 
          const News_Slug_2 = localStorage.getItem('News_Slug_2')
          navigate("/news-and-events/" + News_Slug_2);
          //remove localStorage news slug
          localStorage.removeItem("News_Slug")
        }

      }

    })

  }, [news_event_Slug, news_details_Id])

  const Righthandler = (model_id) => {
    setModel_ID(model_id)
}
const Lefthandler = (model_id) => {
    setModel_ID(model_id)
}


  return (
    <div>
      <section className='news-deatils-section'>
        <div className='news-deatils-inner'>
          {/* <div className='back-cutton'>
            <Link to='/news-and-events'>Back</Link>
          </div> */}
          <h1>{allnewseventDetailsData?.attributes?.Title}</h1>
          <div className='container'>
            <div className='news-deatils-large-image' style={{ backgroundImage: "url(" + Live_API_URL + allnewseventDetailsData?.attributes?.Media?.data?.attributes?.url + "?t=" + Date.now() + ")" }}>
              {/* <img src={Live_API_URL + allnewseventDetailsData?.attributes?.Media?.data?.attributes?.url} alt="news images" /> */}
            </div>
            <p className='info-red'>{allnewseventDetailsData?.attributes?.Title}</p>
            <p className='articleContent' contentEditable='false' dangerouslySetInnerHTML={{ __html: allnewseventDetailsData?.attributes?.Content }}></p>
            {
              console.log(allnewseventDetailsData?.attributes?.Gallery?.data == null)
            }
            {
             allnewseventDetailsData?.attributes?.Gallery?.data != null &&  allnewseventDetailsData?.attributes?.Gallery?.data?.map((photosandvidoesData, index) => (
                <>
                {
                  <a className="button-click" href="#photos">
                    <button onClick={() => openModal(index + 1)} className="newsD-button">
                      <div className='videos-image-block' style={{ backgroundImage: "url(" + Live_API_Images_URL + photosandvidoesData?.attributes?.url + ")", maxWidth: "100%" }}></div>
                    </button>
                  </a>}
                </>
              ))

            }
            {
              allnewseventDetailsData?.attributes?.Gallery?.data != null && 
              <div id="photos" className="overlay">
              <div className="popup">
                <a className="close" href="#">&times;</a>
                  <div className="content">
                      <div className='modal-content-inner'>                    
                        <div className=''>
                            <img
                                key={allnewseventDetailsData?.attributes?.Gallery.data[Image_Vidoes_Id - 1]?.id}
                                src={Live_API_Images_URL + allnewseventDetailsData?.attributes?.Gallery.data[Image_Vidoes_Id - 1]?.attributes?.url + "?t=" + Date.now()}
                                style={{ maxHeight: "100%", maxWidth: "100%" }}
                                alt={allnewseventDetailsData?.attributes?.Gallery.data[Image_Vidoes_Id - 1]?.attributes?.Alt}
                            />
                        </div>
                      </div>
                  </div>
              </div>

              <div className='next-prev-icon-block'>
                  <div className='model-photo button-model-block'>
                      <button style={{ position: 'relative' }} onClick={() => Lefthandler(Image_Vidoes_Id - 1 > 0 ? Image_Vidoes_Id - 1 : 1)} className="left-angle-button"><FaAngleLeft /></button>
                      <button style={{ position: 'relative' }} onClick={() => Righthandler(Image_Vidoes_Id + 1 <= allnewseventDetailsData?.attributes?.Gallery.data.length ? Image_Vidoes_Id + 1 : 1)} className="right-angle-button"><FaAngleRight /></button>
                  </div>
              </div>
          </div>  
            }
          
          </div>
        </div>
      </section>

    </div>
  );
};

export default Newsdetails;