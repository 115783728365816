import axios from "axios";
import ApiHelper from "../helper/ApiHelper";

///call the api helper functions
const ApiHelperData = ApiHelper.getCMCRCData();
///get the live api url
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
///get the user headers api url
const language_id = ApiHelperData.userheadersData


//call the get about us api functions
const getOrganizationStructureApi = () => {
    var org_service_url;
    //check the conditons 
    if (language_id.locale == "en") {
        org_service_url = Live_API_URL + "org-structures?populate=*&locale=" + language_id.locale + "&sort=order:asc"
    }
    else if (language_id.locale == "zh-Hant-HK") {
        org_service_url = Live_API_URL + "org-structures?populate=*&locale=" + language_id.locale + "&sort=order:asc"
    }
    else if (language_id.locale == "zh-CN") {
        org_service_url = Live_API_URL + "org-structures?populate=*&locale=" + language_id.locale + "&sort=order:asc"
    }
    else {
        org_service_url = Live_API_URL + "populate=*"
    }


    return axios.get(org_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//call the get about us api functions
const getMember_OrganizationApi = (member_slug) => {
    var member_service_url;
    //check the conditons 
    if (language_id.locale == "en") {
        member_service_url = Live_API_URL + member_slug+"?populate=*&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        member_service_url = Live_API_URL + member_slug+"?populate=*&locale=" + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        member_service_url = Live_API_URL + member_slug+"?populate=*&locale=" + language_id.locale
    }



    return axios.get(member_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//Exporting About us Services
const MemberOrganizationServices = {
    getOrganizationStructureApi,
    getMember_OrganizationApi
}
export default MemberOrganizationServices;