import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();

//get the live api url functions
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
//get the language id functions
const language_id = ApiHelperData.userheadersData

//call the research achivement arrows functions and passing parameters
const getHerbal_Garden = (herbalParams) => {
  
    var herbal_service_url;
    //check the conditions
    if (language_id.locale == "en") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-Hant-HK") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}-tc&locale=` + language_id.locale
    }
    else if (language_id.locale == "zh-CN") {
        herbal_service_url = Live_API_URL + `articles?filters[slug][$eqi]=${herbalParams}-sc&locale=` + language_id.locale
    }

    return axios.get(herbal_service_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

const getAllPlantsData_ZoneWise = () => {
  
    var plants_data_url; 
    if (language_id.locale != null) {
    plants_data_url = Live_API_URL + `plants?locale=` + language_id.locale +`&populate=*&pagination[pageSize]=300`
    }

    return axios.get(plants_data_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//Exporting to the Herbal Garden Services
const HerbalGardenService = {
    getHerbal_Garden, 
    getAllPlantsData_ZoneWise
}
export default HerbalGardenService;