import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationzhCN from "./locales/zh-cn/translation.json";


//Creating object with the variables of imported translation files
var lang = localStorage.getItem('lang');
if (!lang) {
  lang = 'en';
}
const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  zhCN: {
    translation: translationzhCN,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang, //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;