
import React, { useEffect, useState } from 'react';
import "react-lightbox-pack/dist/index.css";
import { Link, useLocation } from 'react-router-dom';
import ApiHelper from '../../helper/ApiHelper';
import moment from 'moment/moment';
import GalleryServices from '../../services/Gallery.service';
import './Gallery.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { Loader } from './Loader';
import ReactLoading from 'react-loading';
import { t } from 'i18next';
import ReactPlayer from 'react-player';



const Gallery = () => {

    const gallery_Slug = useLocation();
    const gallery_Params = gallery_Slug.pathname.split('/')[1]
    ///call the api helper functions
    const ApiHelperData = ApiHelper.getCMCRCData()
    ///get the live Image api url
    const Live_API_Images_URL = ApiHelperData.Live_Image_Url
    const [galleryData, setGalleryData] = useState([])
    //set the initial value of perpage
    const [perPage] = useState(10);
    //set the initial page count Value
    const [pageCount, setPageCount] = useState(0);
    const [allGalleryData, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        GalleryServices.getGallery(gallery_Params).then((response) => {
            const galleryData = response.data
            setLoading(false)
            setGalleryData(galleryData)
            const data = galleryData.slice(0, perPage);
            setPageCount(Math.ceil(galleryData.length / perPage));
            setData(data);

        })
    }, [])

    //call the event handler for page clicked
    const handlePageClick = (data) => {
        //===count the current page=============
        const currentpage = data.selected;
        //increament the current page value and multiply by the perpages
        const endRange = (currentpage + 1) * perPage;
        //decrement the value of end range and perpage
        const startRange = endRange - perPage
        //set the data according to your paginations 
        setData(allGalleryData.slice(startRange, endRange));
        // //scroll in the top of the page
        window.scrollTo(0, 0);
    }


    return (
        <div>
            <section className='gallery-section'>
                <div className='gallery-loader'>
                    <div className='loder-block'>
                        {loading && <Loader />}
                    </div>
                    <h2 className="title">{t("videos_and_photos")}</h2>
                </div>
            <div className='container'>
                <div className='row'>
                    {allGalleryData.map((allData, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-12 ">
                            <div className='gallery-container' >
                                <div>
                                    { (allData?.attributes.type == "link") ?
                                        <a className="" href={`${allData?.attributes.url}`} target="_blank">
                                            <div className='gallery-large-image' style={{ backgroundImage: "url(" + Live_API_Images_URL + allData?.attributes?.Media?.data?.attributes?.url + ")", maxHeight: "100%", maxWidth: "100%" }}>
                                            </div>
                                        </a>
                                        : 
                                        <Link className="" to={`/photos-and-videos/${allData?.id}`} >
                                            <div className='gallery-large-image' style={{ backgroundImage: "url(" + Live_API_Images_URL + allData?.attributes?.Media?.data?.attributes?.url + ")", maxHeight: "100%", maxWidth: "100%" }}>
                                            </div>
                                        </Link>
                                    }                                                                         
                                </div>
                                <div className='gallery-details'>
                                    <h5>{allData?.attributes?.Name}</h5>
                                    {/* <p className=''>{moment(allData?.attributes?.createdAt).format("DD MMM YYYY")}</p> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='pagination-block'>
                    <div className='gallery-pagination-content-inner'>
                        <ReactPaginate
                            nextLabel=<FaAngleRight />
                            previousLabel=<FaAngleLeft />
                            breakLabel={'...'}
                            onPageChange={handlePageClick}
                            pageCount={pageCount >= 1 ? pageCount : pageCount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            containerClassName="pagination pagination-playerinformation"
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}

                        />
                    </div>
                </div>
            </div>
            </section>
        </div>
    );
};

export default Gallery;

