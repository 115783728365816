import axios from "axios";
import ApiHelper from "../helper/ApiHelper";
//call the get data fucntions
const ApiHelperData = ApiHelper.getCMCRCData();
// get Live api url data
const Live_API_URL = ApiHelperData.Live_API_URL
// const userheadersData = ApiHelperData.userheadersData
// get language id data
const language_id = ApiHelperData.userheadersData


//calling the get about us arrows functions
const getGallery = (gallery_Slug) => {
     var gallery_url = Live_API_URL+gallery_Slug+"?populate=*";
    // if (language_id.locale == "en") {
    //     gallery_url = Live_API_URL + `articles?filters[slug][$eqi]=${gallery_Slug}&locale=` + language_id.locale
    // }
    // else if(language_id.locale == "zh-Hant-HK") {
    //     gallery_url = Live_API_URL + `articles?filters[slug][$eqi]=${gallery_Slug}-tc&locale=` + language_id.locale
    // }
    // else if(language_id.locale == "zh-CN") {
    //     gallery_url = Live_API_URL + `articles?filters[slug][$eqi]=${gallery_Slug}-sc&locale=` + language_id.locale
    // }
  


    return axios.get(gallery_url)
        .then((response) => {
            return response.data
        }).catch((error) => {
            console.log("error", error)
        })
}

//exporting the Contact Us Services
const GalleryServices = {
    getGallery
}
export default GalleryServices;